import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled, { withTheme } from 'styled-components';
import { LayoutRow, SideColumn, MainColumn, FullWidthBackground } from '../utils/layout-row';
import media from '../utils/media';
import Menu from './menu';
import Logo from '../content/general/webology-logo.svg';
import Hamburger from './hamburger';

const HeaderSpacer = styled.div`
padding-bottom: ${props => props.theme.headerHeightPixelsMob}px;
padding-bottom: calc(${props => props.theme.headerHeightPixelsMob}px + 60px);

  ${media.tab`
    padding-bottom: ${props => props.theme.headerHeightPixelsTab}px;
    padding-bottom: calc(${props => props.theme.headerHeightPixelsTab}px + 60px);
  `}

  ${media.desk`
    padding-bottom: ${props => props.theme.headerHeightPixelsDesk}px;
    padding-bottom: calc(${props => props.theme.headerHeightPixelsDesk}px + 60px);
  `}
`;

const HeaderMob = styled.div`
  ${media.tab`display: none;`}
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
`;

const MobMenuContainer = styled.div`
  ${media.tab`display: none;`}
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  visibility: hidden;

  &.show-mob-menu {
    visibility: visible;
  }
`;

const MobContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: ${props => props.theme.headerHeightPixelsMob}px;
`;

const MobHeaderBackground = styled(FullWidthBackground)`
  background: #fff;
  opacity: 0.9;
  transition-duration: 100ms;
  transition-timing-function: ease;

  &.show-mob-menu {
    opacity: 0;
  }
`;

const MobMenuBackground = styled(FullWidthBackground)`
  background: ${props => props.theme.mobMenuBackgroundColor};
  opacity: 0;
  height: 100vh;
  transition-duration: 300ms;
  transition-timing-function: ease;

  &.show-mob-menu {
    transition-duration: 600ms;
    opacity: 0.98;
  }
`;

const MenuMob = styled(Menu)`
  opacity: 0;
  transition-duration: 100ms;
  transition-timing-function: ease;

  padding-top: 20vh;
  > * {
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &.show-mob-menu {
    transition-duration: 100ms;
    opacity: 1;
  }
`;

const LogoLink = styled(Link)`
  width: 142px;
  outline: none;
  line-height: 0;
  margin-bottom: -4px;

  &:focus {
    outline: ${props => props.theme.buttonFocusOutline};
    outline-offset: 2px;
  }
`;

const StyledLogo = styled(Logo)`
  margin-bottom: 0;

  .logo-web,
  .log-ology {
    transition-duration: 100ms;
    transition-timing-function: ease;
  }

  .logo-web { fill: ${props => props.theme.logoWebColor}; }
  .logo-ology { fill: ${props => props.theme.logoOlogyColor}; }

  &.show-mob-menu {
    .logo-web { fill: #fff; }
    .logo-ology { fill: #ccc; }
  }
`;

const HeaderDesk = styled.div`
  ${media.mobOnly`display: none;`}
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
`;

const LayoutRowDeskMain = styled(LayoutRow)`
  height: ${props => props.theme.headerHeightPixelsTab}px;
  grid-template-rows: ${props => props.theme.headerHeightPixelsTab}px;
  align-items: center;
  background: #fff;
  background: rgba(255,255,255,0.9);

  ${media.desk`
    height: ${props => props.theme.headerHeightPixelsDesk}px;
    grid-template-rows: ${props => props.theme.headerHeightPixelsDesk}px;
  `}
`;

const LayoutRowDeskTagline = styled(LayoutRow)`
  position: fixed;
  top: ${props => props.theme.headerHeightPixelsTab}px;
  pointer-events: none;

  ${media.desk`
    top: ${props => props.theme.headerHeightPixelsDesk}px;
  `}
`;

const SideColumnDeskLogo = styled(SideColumn)`
  height: 100%;
  display: flex;
  align-items: center;

  >* {
    display: block;
    flex-grow: 0;
  }
`;

const MainColumnDeskMenu = styled(MainColumn)`
  height: 100%;
`;

const Tagline = styled.small`
  display: block;
  white-space: pre-line;
  color: ${props => props.theme.taglineColor};
`;

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.onHamburgerChange = this.onHamburgerChange.bind(this);

    this.state = {
      isHamburgerOpen: false
    };
  }

  onHamburgerChange(isOpen) {
    this.setState({ isHamburgerOpen: isOpen });
    this.props.onShowMobMenuChange(isOpen);
  }

  render() {
    const showMobMenuClassName = this.state.isHamburgerOpen
      ? 'show-mob-menu'
      : '';

    return (
      <header>
        <MobMenuContainer className={showMobMenuClassName}>
          <LayoutRow>
            <MobMenuBackground className={showMobMenuClassName}/>
            <MainColumn>
              <MenuMob
                className={showMobMenuClassName}
                location={this.props.location}
                invertcolors={true}
                vertical={true}
                isSmall={false} />
            </MainColumn>
          </LayoutRow>
        </MobMenuContainer>

        <HeaderMob>
          <LayoutRow>
            <MobHeaderBackground className={showMobMenuClassName}/>

            <MainColumn>
              <MobContainer>
                <LogoLink to="/">
                  <StyledLogo
                    className={showMobMenuClassName}
                    alt={this.props.siteName} />
                </LogoLink>
                <Hamburger
                  onChange={this.onHamburgerChange}
                  twoLines={this.props.theme.hamburgerTwoLines}
                  color={ this.state.isHamburgerOpen ? '#fff' : this.props.theme.menuLinkColor }
                  widthPixels={this.props.theme.hamburgerWidthPixels}
                  heightPixels={this.props.theme.hamburgerHeightPixels}
                  targetWidthPixels={this.props.theme.headerHeightPixelsMob}
                  targetHeightPixels={this.props.theme.headerHeightPixelsMob}
                  linePixels={this.props.theme.hamburgerLinePixels}
                  borderRadius={this.props.theme.hamburgerBorderRadius}
                  transitionTimingFunction="ease"
                  transitionDurationMS={600}/>
              </MobContainer>
            </MainColumn>
          </LayoutRow>

        </HeaderMob>

        <HeaderDesk>
          <LayoutRowDeskMain>
            <SideColumnDeskLogo>
              <LogoLink to="/">
                <StyledLogo alt={this.props.siteName} />
              </LogoLink>
            </SideColumnDeskLogo>
            <MainColumnDeskMenu>
              <Menu location={this.props.location}/>
            </MainColumnDeskMenu>
          </LayoutRowDeskMain>
          <LayoutRowDeskTagline>
            <SideColumn>
              <Tagline>{this.props.tagline}</Tagline>
            </SideColumn>
          </LayoutRowDeskTagline>
        </HeaderDesk>
      </header>
    );
  }
}

Header.defaultProps = {
  onShowMobMenuChange: (isOpen) => {},
};

Header.propTypes = {
  onShowMobMenuChange: PropTypes.func,
};

const ThemedHeader = withTheme(Header);
export { HeaderSpacer, ThemedHeader as default };
